import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/mapbox-gl/dist/mapbox-gl.css");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/ClientProviders/ClientOnly.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/ClientProviders/ClientProviders.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/TranslationsProvider/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/lib/registry.tsx");
